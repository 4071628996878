import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";


import Home from "./components/payment/home";
// import Pay from "./components/payment/Pay";
// import PhysicianInforation from "./components/payment/PhysicianInforation";
import ClientInforation from "./components/payment/ClientInforation";
import FormsOtherDocuments from "./components/payment/FormsOtherDocuments";
import ClientActivity from "./components/payment/ClientActivity";
import InitialProcessing from "./components/payment/InitialProcessing";
import PaymentInformation from "./components/payment/PaymentInformation";
import InfectiousDisease from "./components/payment/InfectiousDisease";
import ClientConsent from "./components/payment/ClientConsent";
import ForgotPassword from "./components/payment/ForgotPassword";
import StemCellRequest from "./components/payment/StemCellRequest";
import ActBankingStrorage from "./components/payment/ActBankingStrorage";
import Repayment from "./components/payment/Repayment";
import Updatepaymentinfo from "./components/payment/updatepaymentinfo";
import Store from "./components/payment/Store";




// import "./App.css";



class App extends Component {
  /*<Navbar />*/
  render() {
    return (
      <Provider  store={store}>
        <Router>
          <div className="App">

            <Route>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/client-information" component={ClientInforation} />
                <Route exact path="/form-document/:id" component={FormsOtherDocuments} />                
                <Route exact path="/client-activity" component={ClientActivity} />     
                <Route exact path="/initial-processing" component={InitialProcessing} />                    
                <Route exact path="/payment-information" component={PaymentInformation} />     
                <Route exact path="/infectious-disease" component={InfectiousDisease} />     
                <Route exact path="/client-consent" component={ClientConsent} />     
                <Route exact path="/forgot-password" component={ForgotPassword} />     
                <Route exact path="/stem-cell-request" component={StemCellRequest} />     
                <Route exact path="/stem-cell-request/:id" component={StemCellRequest} />     
                <Route exact path="/ACT-banking-storage-plans" component={ActBankingStrorage} />     
                <Route exact path="/updatecreditcard" component={Repayment} />  
                <Route exact path="/updatepaymentinformation" component={Updatepaymentinfo} />  
                <Route exact path="/store" component={Store} />     


              </Switch>
            </Route>        

          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
