/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";

import { updatePassword,getOtp,verifyClientData,setPaymentInfo } from '../../actions/payActions'

class ForgotPassword extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{Payment_Type:"CC",PaymentMethod:"Credit Card"},
            login:true,
            message:"",
            otp:"",
            dateOfBirth:"",
            clientACTId:"",
            client_info:"",
            verify_client:false,
            

        }
    }

    componentDidMount() { 
        // this.props.updatePassword(newList, this.props.history);

        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
        this.setState({myFutureDate:myFutureDate})

        // console.log('clientACTId ---------- ', localStorage.getItem('clientACTId'));

        // this.setState({clientACTId:localStorage.getItem('clientACTId')})

         
    }

    verifyClient = (e) => {
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        if(this.state.client_info=="client_id"){
      
        if(!fields["ClientId"]){
            formIsValid = false;
            errors["ClientId"] = "Please enter Client Id";
            let element = document.getElementById("ClientId");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            this.setState({isChecked: false});
            element.className = "form-control error"
        }
        if(!fields["ClientLastname"]){
            formIsValid = false;
            errors["ClientLastname"] = "Please enter client last name";
            let element = document.getElementById("ClientLastname");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            this.setState({isChecked: false});
        }
      }
      
      
      
      
      if(this.state.client_info=="full_contact"){
      
        if(!fields["ClientFirstName"]){
            formIsValid = false;
            errors["ClientFirstName"] = "Please enter client first name";
            let element = document.getElementById("ClientFirstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
      
        if(!fields["ClientLastname"]){
            formIsValid = false;
            errors["ClientLastname"] = "Please enter client last name";
            let element = document.getElementById("ClientLastname");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
      
        if(!fields["ClientEmail"]){
            formIsValid = false;
            errors["ClientEmail"] = "Please enter client email";
            let element = document.getElementById("ClientEmail");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
      
        if(fields["ClientEmail"] != "" && !validator.isEmail(fields["ClientEmail"]) ){
          formIsValid = false;
          errors["ClientEmail"] = "Please enter valid client email";
          let element = document.getElementById("ClientEmail");                
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({top: y, behavior: 'smooth'});
          element.className = "form-control error"
      }
      
        if(!fields["ClientDob"]){
            formIsValid = false;
            errors["ClientDob"] = "Please enter client Dob";
            let element = document.getElementById("ClientDob");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
      
      }
        this.setState({errors: errors});
      
        // console.log('check value => ',this.state.isChecked)
        if(formIsValid){
            // this.setState({ loading: true });  
            this.setState({isChecked: true});
            if(this.state.client_info=="client_id"){
                var newList = {ClientId:fields["ClientId"],ClientLastname:fields["ClientLastname"]}
            }else{
                var newList = this.state.formData;
            }
           
           
            // console.log("newlist=>", newList);
            this.props.verifyClientData(newList, this.props.history);
            this.setState({loading:true})
            
        }
      
      
      
      
      }

   

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
    }
    

    datechange(e){
        // new Date(e);
        this.state.formData['dateOfBirth'] = Moment(e).format('MM/DD/YYYY');
        // consol
        this.setState({dateOfBirth:e})
    }


    

    componentWillReceiveProps(nextProps) {
        console.log('nextProps ----------',nextProps); 

        
        if(nextProps.page_name.payment_info){

            this.setState({loading:false})
           
            if(nextProps.page_name.payment_info.error){
                this.setState({api_error:nextProps.page_name.payment_info.error})
            }else{
                this.state.formData['TransactionId'] = nextProps.page_name.payment_info.TransactionId
                this.setState({payment_response:nextProps.page_name.payment_info.page_name})
            }
            
            this.setState({loading:false})
            window.scrollTo(0, 0);     
    
        }
      

       


    if(nextProps.page_name.client_info){
        this.setState({loading:false})
        var re = JSON.parse(nextProps.page_name.client_info)
        // console.log('response => ',re.error)
        if(re.error != null){
            let errors = {};
            // document.getElementById("Client_id").value = ""; 
            // document.getElementById("Client_lastname").value = "";                               
            this.state.formData['Client_id']="";
            this.state.formData['Client_lastname']="";
            this.setState({Client_id:""}) 
            this.setState({Client_lastname:""}) 
            errors["Client_id"] = "Please enter valid Client ID or last name";
            this.setState({errors: errors});
            this.setState({err_msg:"Please enter valid client ID and last name. Contact ACT directly incase of any queries."});
            this.setState({disableField:false}); 
            this.setState({verify_client:false})
        }else{
            this.setState({verify_client:true})
            // console.log('client info for -----------',re);
            
            this.state.formData['ClientSFRecordId']=re.ClientSFRecordId;

            const currentDate = new Date();
            const BankedInDate = new Date(re.BankedInDate); // Replace with your desired exam date
            
            const timeDifference = Math.abs(BankedInDate - currentDate);
            const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            const LastBioInsurancePurchasedDate = new Date(re.LastBioInsurancePurchasedDate); // Replace with your desired exam date
            const Bio_timeDifference = Math.abs(LastBioInsurancePurchasedDate - currentDate);
            const Bio_daysRemaining = Math.ceil(Bio_timeDifference / (1000 * 60 * 60 * 24));


            console.log('last purchase date ',re.LastBioInsurancePurchasedDate);
            console.log('daysRemaining ---------',daysRemaining);
            console.log('Bio_daysRemaining ---------',Bio_daysRemaining);
            console.log('ClientSFRecordId   ---------',this.state.formData['ClientSFRecordId']);
            

            var myFutureDate=new Date(currentDate);
           


            this.setState({BankedInDate:re.BankedInDate});
            this.setState({success_msg:"Client Details Successfully Confirmed"});  
            this.setState({err_msg:""});

            this.setState({disableField:true});
            // document.getElementById("ShipmentDate").value = "";  
            this.state.ShipmentDate = "";
                            

        }
                
                
            }
 

        
        }

    sendOtp = (e) => {
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Please enter email";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Please enter firstName";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["dateOfBirth"]){
            formIsValid = false;
            errors["dateOfBirth"] = "Please enter dateOfBirth";
            let element = document.getElementById("dateOfBirth");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = {firstName:this.state.formData['firstName'], dateOfBirth:this.state.formData['dateOfBirth'],email:this.state.formData['Email'] };
            // console.log("newlist=>", newList);
            this.props.getOtp(newList, this.props.history);
            }
    }

    checkradio2 = (e) => {
        this.state.formData['client_info'] = e.target.id;
        this.setState({client_info:e.target.id})
    
        // console.log('client info = ', this.state.client_info)
        
    }
    
    datechange2(e){
        // new Date(e);
        this.state.formData['ClientDob'] = Moment(e).format('MM/DD/YYYY');
        // console.log('selected date =>', this.state.formData['ClientDob']);
        this.setState({ClientDob:e})
    }


    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["agree"]){
            formIsValid = false;
            errors["agree"] = "Please Check ";
            let element = document.getElementById("agree");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
           
        }

        if(this.state.verify_client==false && this.state.client_info !=""){
            formIsValid = false;
            errors["verify_client"] = "Please confirm client details";
            let element = document.getElementById("verify_client");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            // this.setState({isChecked: false});
            // element.className = "form-control error"
          }
          
        
          if(this.state.client_info ==""){
            formIsValid = false;
            errors["client_info"] = "Please Check ";
            let element = document.getElementById("full_contact");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
        
        if(this.state.client_info=="client_id"){
        
            if(!fields["ClientId"]){
                formIsValid = false;
                errors["ClientId"] = "Please enter Client Id";
                let element = document.getElementById("ClientId");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
            if(!fields["ClientLastname"]){
                formIsValid = false;
                errors["ClientLastname"] = "Please enter client last name";
                let element = document.getElementById("ClientLastname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        
        }
        
        if(this.state.client_info=="full_contact"){
        
            if(!fields["ClientFirstName"]){
                formIsValid = false;
                errors["ClientFirstName"] = "Please enter client first name";
                let element = document.getElementById("ClientFirstName");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        
            if(!fields["ClientLastname"]){
                formIsValid = false;
                errors["ClientLastname"] = "Please enter client last name";
                let element = document.getElementById("ClientLastname");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        
            if(!fields["ClientEmail"]){
                formIsValid = false;
                errors["ClientEmail"] = "Please enter client email";
                let element = document.getElementById("ClientEmail");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        
            if(!fields["ClientDob"]){
                formIsValid = false;
                errors["ClientDob"] = "Please enter client Dob";
                let element = document.getElementById("ClientDob");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            }
        
            if(fields["ClientDob"]){
              const dateFormat = 'MM-DD-YYYY';
              const yr = new Date(fields["ClientDob"]).getFullYear();
              const toDateFormat = Moment(new Date(fields["ClientDob"])).format(dateFormat);
              let validDate = Moment(toDateFormat, dateFormat, true).isValid();
              if(!validDate || yr >= 2050){
                  formIsValid = false;
                  errors["ClientDob"] = "Invalid date ";
                  let element = document.getElementById("ClientDob");                
                  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'smooth'});
                  element.className = "form-control error"
              }
            }
        
        }
        
        if(!fields["Credit_Card_Number"]){
            formIsValid = false;
            errors["Credit_Card_Number"] = "Please enter credit card number";
            let element = document.getElementById("Credit_Card_Number");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(fields["Credit_Card_Number"]) {
            let cardvalid = valid.number(fields["Credit_Card_Number"]);
            // console.log('cardvalid',cardvalid);
            if(!cardvalid.isValid){
                formIsValid = false;
                errors["Credit_Card_Number"] = "Invalid credit card number.";
                let element = document.getElementById("Credit_Card_Number");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            }
        }

        
        if(!fields["Credit_Card_Biling_Email_Address"]){
            formIsValid = false;
            errors["Credit_Card_Biling_Email_Address"] = "Cannot be empty";
            let element = document.getElementById("Credit_Card_Biling_Email_Address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(fields["Credit_Card_Biling_Email_Address"] && !validator.isEmail(fields["Credit_Card_Biling_Email_Address"])) {
            formIsValid = false;
            errors["Credit_Card_Biling_Email_Address"] = "Invalid email.";
            let element = document.getElementById("Credit_Card_Biling_Email_Address");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error" 
             
        }


        if(!fields["Credit_Card_Holder_First_Name"]){
            formIsValid = false;
            errors["Credit_Card_Holder_First_Name"] = "Field cann't be empty";
            let element = document.getElementById("Credit_Card_Holder_First_Name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }


        if(!fields["Credit_Card_Holder_Last_Name"]){
            formIsValid = false;
            errors["Credit_Card_Holder_Last_Name"] = "Field cann't be empty";
            let element = document.getElementById("Credit_Card_Holder_Last_Name");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["CVV"]){
            formIsValid = false;
            errors["CVV"] = "Field cann't be empty";
            let element = document.getElementById("CVV");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Credit_Card_Type"]){
            formIsValid = false;
            errors["Credit_Card_Type"] = "Field cann't be empty";
            let element = document.getElementById("Credit_Card_Type");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }



        if(fields["CVV"] && !/^\d{3,4}$/.test(fields["CVV"])) {
            formIsValid = false;
            errors["CVV"] = "Invalid CVV code"; 
            let element = document.getElementById("CVV");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});   
            element.className = "form-control error"            
        }

        if(!fields["Month_of_Expiry"]){
            formIsValid = false;
            errors["Month_of_Expiry"] = "Cannot be empty";
            let element = document.getElementById("Month_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
        } 

        if(fields["Month_of_Expiry"]){

            let expMonth = valid.expirationMonth(fields["Month_of_Expiry"]);
            // console.log('expmonth',expMonth.isPotentiallyValid);
            if(!expMonth.isPotentiallyValid){
                formIsValid = false;
                errors["Month_of_Expiry"] = "Invalid credit Card Expiration Month";
                let element = document.getElementById("Month_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            }
        }

        if(!fields["Year_of_Expiry"]){
            formIsValid = false;
            errors["Year_of_Expiry"] = "Cannot be empty";
            let element = document.getElementById("Year_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
                element.className = "form-control error"
            } 

        if(fields["Year_of_Expiry"]){
            let expirationYear = valid.expirationYear(fields["Year_of_Expiry"]);
            // console.log('expirationYear',expirationYear.isPotentiallyValid);
            if(!expirationYear.isPotentiallyValid){
                formIsValid = false;
                errors["Year_of_Expiry"] = "Invalid credit Card Expiration Year";
                let element = document.getElementById("Year_of_Expiry");                
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
            }
        }

            if(fields["Year_of_Expiry"] && fields["Month_of_Expiry"]){
                let expirationDate = valid.expirationDate(fields["Month_of_Expiry"]+"/"+fields["Year_of_Expiry"]);
                // console.log('expirationDate',expirationDate);
                if(!expirationDate.isPotentiallyValid){
                    formIsValid = false;
                    errors["Year_of_Expiry"] = "Something Wrong with Expiration Year";
                    let element = document.getElementById("Year_of_Expiry");                
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});

                    errors["Month_of_Expiry"] = "Something Wrong with Expiration Month";
                    let element1 = document.getElementById("Month_of_Expiry");                
                    element1.scrollIntoView({behavior: 'smooth'});
                    element.className = "form-control error"
                }
            }



        if(!fields["Credit_Card_Billing_Zip_Code"]){
            formIsValid = false;
            errors["Credit_Card_Billing_Zip_Code"] = "Field cann't be empty";
            let element = document.getElementById("Credit_Card_Billing_Zip_Code");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Country"]){
            formIsValid = false;
            errors["Country"] = "Field cann't be empty";
            let element = document.getElementById("Country");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

 

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  

            console.log(' formData ------- ', this.state.formData);
            const newList = this.state.formData;
            delete newList.ClientId;
            delete newList.ClientFirstName;
            delete newList.ClientLastname;
            delete newList.ClientEmail;
            delete newList.ClientDob;
            delete newList.client_info;
            delete newList.agree;
            
            
            // newList['PhysicianId'] = this.state.paymentInfo['PhysicianId']
            console.log("newlist=>", newList);
            newList['PaymentSource'] = 'Email Single Payment Page';
            this.setState({loading:true})
            this.props.setPaymentInfo(newList, this.props.history);
            }

    }    

    render() {
        return (
            <MainLayout>
                <title>American Cell Technology</title>
                {/* <Header></Header> */}
                <div className="login-wrapper">
                    <div className="login-container">
                        <div className="login-logo-area"><img src="images/logo-main.svg" alt="" /></div>
                        { (this.state.payment_response)?
                    <div className="col-md-12">
                        <h5 style={{paddingTop:"10px"}}><i>Payment Information is Updated Sucessfully</i> </h5>
                    </div>:
                        <div className="login-box" style={{width:'700px'}}>
                        {(this.state.error != "")?<span style={{color:'red'}}> {this.state.error} </span>:''}  
                            {(this.state.message != "")?<span style={{color:'green'}}> {this.state.message}</span>:''}                            
                            <form className="needs-validation" noValidate onSubmit={this.onSubmit}>

                            <h2 className="title-2">Client Information</h2>
                            <div className="choose-options-sec">
                                    <div className="custom-control custom-radio">
                                    <input type="radio" id="client_id" name="client_info" className="custom-control-input" onChange={this.checkradio2} />
                                    <label className="custom-control-label" for="client_id">Client ID and Last Name</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                    <input type="radio" id="full_contact" name="client_info" className="custom-control-input" onChange={this.checkradio2} />
                                    <label className="custom-control-label" for="full_contact">Client Full Contact Information</label>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.errors["client_info"]}</span>
                            </div>

                                                    
                            <div className="submission-detail-sec">
                                {(this.state.client_info=="client_id")?
                                
                                <div className="row">
                                  
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Client ID <span className="required">*</span> </label>
                                        <input type="text" id="ClientId" className="form-control" placeholder="Client ID" onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientId"]}</span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Last Name<span className="required">*</span> </label>
                                        <input type="text" id="ClientLastname" className="form-control" placeholder=""  onChange={this.onChange} />
                                        <span style={{color: "red"}}>{this.state.errors["ClientLastname"]}</span>
                                    </div>
                                </div>
                                </div>:""}

                                {(this.state.client_info=="full_contact")?
                                <div>
                                <div className="row">
                                
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client First Name <span className="required">*</span> </label>
                                            <input type="text" name="ClientFirstName" id="ClientFirstName" className="form-control" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["ClientFirstName"]}</span>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Last Name<span className="required">*</span> </label>
                                            <input type="text" name="ClientLastname" id="ClientLastname" className="form-control" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["ClientLastname"]}</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Email Address <span className="required">*</span> </label>
                                            <input type="text" name="ClientEmail" id="ClientEmail" className="form-control" placeholder="" onChange={this.onChange} />
                                            <span style={{color: "red"}}>{this.state.errors["ClientEmail"]}</span>
                                            
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Client Date of Birth <span className="required">*</span> </label>
                                            {/* <input type="text" name="ClientDob" id="ClientDob" className="form-control" placeholder="Dob" onChange={this.onChange} /> */}
                                            <DatePicker id="ClientDob" className="form-control bggray" maxDate={new Date()} placeholderText="mm/dd/yyyy" selected={this.state.ClientDob}  onChange={date => this.datechange2(date)} /> <br />
                                            <span style={{color: "red"}}>{this.state.errors["ClientDob"]}</span>
                                        </div>
                                    </div>
                                </div> </div>:""}

                                    {(this.state.client_info!="")?
                                    <div className="form-group col-md-6">
                                    <button type="button" className="btn btn-secondary" id="verify_client" onClick={this.verifyClient}>Confirm Client Details</button>
                                    <br /><span style={{color: "red"}}>{this.state.errors["verify_client"]}</span>  <br/> 
                                    <span style={{color: "green",margin:"1%"}}> {this.state.success_msg}</span>                                 
                                    <span style={{color: "red",margin:"1%"}}> {this.state.err_msg}</span>                                 
                                    </div>:""}

                                </div>
                           
                          <div class="">
                            {/* <img src="images/secure_pay.png" alt="" /> */}
                                    <h4>Update Credit Card Information </h4>
                                    <div class="card-detail-form">
                                        <div class="card-detail-wrap mb-5">
                                        <h5>Credit Card Detail <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title="Tooltip Text" alt="" /></h5>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Credit Card Number <span class="required">*</span> </label>
                                                    <input type="text" id="Credit_Card_Number" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Number"]}</span>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Credit Card Type <span class="required">*</span> </label>                                                
                                                <select className="custom-select d-block w-100" id="Credit_Card_Type" onChange={this.onChange} >
                                                    <option value="">Select Credit Card </option>
                                                    <option value="American Express">American Express</option>
                                                    <option value="Discover">Discover</option> 
                                                    <option value="Visa">Visa</option> 
                                                    <option value="Mastercard">Mastercard</option>                                                 
                                                </select>
                                                <span style={{color: "red"}}>{this.state.errors["Credit_Card_Type"]}</span>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="row">

                                            {/* <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Expiry Date <span class="required">*</span> </label>
                                                    <input type="text" id="" class="form-control" placeholder="-- --  /  -- -- -- --" />
                                                </div>
                                            </div> */}

                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>CVV <span class="required">*</span> </label>
                                                    <input id="CVV" type="password" class="form-control" placeholder="***" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["CVV"]}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mb-3">
                                            <label htmlFor="Month_of_Expiry">Card Expiration Date <span className="act_required">*</span></label>
                                            <select className="custom-select d-block w-100" id="Month_of_Expiry" onChange={this.onChange}  placeholder="mm" >
                                                <option value="">Select Month</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option> 
                                                <option value="03">03</option> 
                                                <option value="04">04</option> 
                                                <option value="05">05</option>    
                                                <option value="06">06</option> 
                                                <option value="07">07</option> 
                                                <option value="08">08</option> 
                                                <option value="09">09</option> 
                                                <option value="10">10</option> 
                                                <option value="11">11</option>
                                                <option value="12">12</option>  
                                            </select>
                                            {/* <input type="text" className="form-control" id="Month_of_Expiry" onChange={this.onChange} placeholder="mm" required /> */}
                                            <span style={{color: "red"}}>{this.state.errors["Month_of_Expiry"]}</span>
                                            <div className="invalid-feedback">
                                            Valid Month required
                                            </div>
                                            </div>

                                                <div className="col-md-3 mb-3">
                                                <label htmlFor="creditCardExpirationYear"> &nbsp;</label>
                                                <select className="custom-select d-block w-100" id="Year_of_Expiry" onChange={this.onChange}  placeholder="YYYY" >
                                                <option value="">Year</option>
                                                {/* <option value="2021">2021</option> */}
                                                
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                </select>
                                                {/* <input type="text" className="form-control" id="creditCardExpirationYear" onChange={this.onChange} placeholder="yy" required /> */}
                                                
                                                <span style={{color: "red"}}>{this.state.errors["Year_of_Expiry"]}</span><div className="invalid-feedback">
                                                Valid Year required
                                                </div>
                                                </div>
                                                </div>
                                                <div class="row">

                                            
                                            <div class="col-md-6">
                                                <div class="card-logo-list">
                                                <div><img src="images/card-1.svg" alt="" /></div>
                                                <div><img src="images/card-2.svg" alt="" /></div>
                                                <div><img src="images/card-3.svg" alt="" /></div>
                                                <div><img src="images/card-4.svg" alt="" /></div>
                                                    </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="card-detail-wrap">
                                            <h5>Billing Address <img src="images/info.svg" data-toggle="tooltip" data-placement="top" title="Tooltip Text" alt="" /></h5>
                                        <div class="row">
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" id="Credit_Card_Holder_First_Name" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_First_Name"]}</span>
                                                </div>
                                            </div>
                                            
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" id="Credit_Card_Holder_Last_Name" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Holder_Last_Name"]}</span>
                                                </div>
                                            </div>

                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Biling Email</label>
                                                    <input type="email" id="Credit_Card_Biling_Email_Address" class="form-control" placeholder="" onChange={this.onChange} />
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Biling_Email_Address"]}</span>
                                                </div>
                                            </div>


                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <input type="text" id="Country" class="form-control" placeholder="" onChange={this.onChange} /> 
                                                    <span style={{color: "red"}}>{this.state.errors["Country"]}</span>
                                                
                                                </div>
                                            </div>
                                            </div>
                                            <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Zip/Postal Code</label>
                                                    <input type="text" id="Credit_Card_Billing_Zip_Code" class="form-control" placeholder="" onChange={this.onChange} /> 
                                                    <span style={{color: "red"}}>{this.state.errors["Credit_Card_Billing_Zip_Code"]}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                            <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="agree" value="yes" onChange={this.onChange} />
                                                <label className="custom-control-label" for="agree"> By checking this box, I confirm that I am the owner of this account and authorize American Cell Technology (ACT) to store my credit card information securely. I understand that this information will be used to charge any overdue fees associated with this account.</label><br />
                                                <span style={{color: "red"}}>{this.state.errors["agree"]}</span>
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                            
                                <div class="button-area">
                            <button type="button" class="btn btn-outline-primary mr-2">Cancel</button>
                            <button type="submit" class="btn btn-primary">Save</button>
                        </div>
                            
                            
                                                     
                          
                                
                                

                               
                                
                            </form>
                        </div>}
                    </div>
                </div>
                
            </MainLayout>
        );
    }
}



ForgotPassword.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    updatePassword:PropTypes.func.isRequired,
    setPaymentInfo:PropTypes.func.isRequired,
    verifyClientData:PropTypes.func.isRequired,
    
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {updatePassword,getOtp,verifyClientData,setPaymentInfo})(ForgotPassword);