/* eslint-disable no-unused-vars */
import React, { Component , useRef}  from "react";
// import  { useRef   }  from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from 'moment';
import validator from 'validator';
import MainLayout from "../layout/main-layout";
import Header from '../layout/Header';
import Left from '../layout/Left';
import DatePicker from "react-datepicker";
import valid from "card-validator";
import "react-datepicker/dist/react-datepicker.css";

import { updatePassword,getOtp } from '../../actions/payActions'

class ForgotPassword extends Component {

    constructor(props){
        super(props);
        this.state={
            error:'',
            errors:{},
            formData:{},
            login:true,
            message:"",
            otp:"",
            dateOfBirth:"",
            clientACTId:""

        }
    }

    componentDidMount() { 
        // this.props.updatePassword(newList, this.props.history);

        var myCurrentDate=new Date();
        var myFutureDate=new Date(myCurrentDate);
        this.setState({myFutureDate:myFutureDate})

        console.log('clientACTId ---------- ', localStorage.getItem('clientACTId'));

        // this.setState({clientACTId:localStorage.getItem('clientACTId')})

         
    }

    onChange = (e) => {  
        this.state.formData[e.target.id] = e.target.value; 
        // console.log('form vlaue=>', this.state.formData);
    }

    datechange(e){
        // new Date(e);
        this.state.formData['dateOfBirth'] = Moment(e).format('MM/DD/YYYY');
        // consol
        this.setState({dateOfBirth:e})
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps ----------',nextProps); 

        var result = {}
        // let result = JSON.parse(nextProps.page_name.res) 
        if(nextProps.page_name.res){
            result = nextProps.page_name.res
            console.log('result ->>>>>>>> ', result)
        this.setState({clientACTId:result.clientACTId})

        }
        
        
        
        
        if(result.error){
            this.setState({error:result.error});
            Array.from(document.querySelectorAll("input")).forEach(
                input => (input.value = "")
                );
                        
        }

        if(result.message){
            this.setState({message:result.message});
            this.setState({error:""});
            this.setState({otp:""});
           
        }

        if(result.otp){
            this.setState({otp:result.otp});
            this.setState({message:""});                        
        }

        if(nextProps.page_name.response){            
            result = nextProps.page_name.response
            if(result.error){
                this.setState({error:result.error});
            }else{
                this.setState({message:result.message});
            }

            if(result.message){
                this.setState({message:result.message});
                this.setState({otp:""});
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                    );
            }
          
        }

        // console.log('nextProps => ', nextProps.page_name)

        
        }

    sendOtp = (e) => {
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Please enter email";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Please enter firstName";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["dateOfBirth"]){
            formIsValid = false;
            errors["dateOfBirth"] = "Please enter dateOfBirth";
            let element = document.getElementById("dateOfBirth");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});

        if(formIsValid){
            // this.setState({ loading: true });  
            const newList = {firstName:this.state.formData['firstName'], dateOfBirth:this.state.formData['dateOfBirth'],email:this.state.formData['Email'] };
            // console.log("newlist=>", newList);
            this.props.getOtp(newList, this.props.history);
            }
    }

    onSubmit = (e) => {              
        e.preventDefault();    
        // window.scrollTo(0, 500);
          
        let fields = this.state.formData;
        let errors = {};
        let formIsValid = true;
        // let scroll = {};
        const yOffset = -30;
        
        if(!fields["OneTimePassword"]){
            formIsValid = false;
            errors["OneTimePassword"] = "Please enter One Time Password";
            let element = document.getElementById("OneTimePassword");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["UpdatedPassword"]){
            formIsValid = false;
            errors["UpdatedPassword"] = "Please enter password";
            let element = document.getElementById("UpdatedPassword");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["ConfirmPassword"]){
            formIsValid = false;
            errors["ConfirmPassword"] = "Please enter password";
            let element = document.getElementById("ConfirmPassword");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(fields["ConfirmPassword"] != fields["UpdatedPassword"] ){
            formIsValid = false;
            errors["ConfirmPassword"] = "Password doesn't match.";
            let element = document.getElementById("ConfirmPassword");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        if(!fields["Email"]){
            formIsValid = false;
            errors["Email"] = "Please enter email";
            let element = document.getElementById("Email");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }
        if(!fields["firstName"]){
            formIsValid = false;
            errors["firstName"] = "Please enter firstName";
            let element = document.getElementById("firstName");                
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
            element.className = "form-control error"
        }

        // console.log('formIsValid --- ', formIsValid)

        this.setState({errors: errors});
        
        if(formIsValid){
            // this.setState({ loading: true });  
            // const newList = this.state.formData;
            const newList = {ClientId:this.state.clientACTId,UpdatedPassword:fields["UpdatedPassword"],OneTimePassword:fields["OneTimePassword"]};
            // console.log("newlist=>", newList);
            this.props.updatePassword(newList, this.props.history);
            }

    }

    render() {
        return (
            <MainLayout>
                <title>American Cell Technology</title>
                {/* <Header></Header> */}
                <div className="login-wrapper">
                    <div className="login-container">
                        <div className="login-logo-area"><img src="images/logo-main.svg" alt="" /></div>
                        <div className="login-box">
                        {(this.state.error != "")?<span style={{color:'red'}}> {this.state.error} </span>:''}  
                            {(this.state.message != "")?<span style={{color:'green'}}> {this.state.message}</span>:''}                            
                            <form className="needs-validation" noValidate onSubmit={this.onSubmit}>
                           
                              
                            <div className="form-group">
                            <label>Email</label>
                            <input type="email" id="Email" className="form-control" placeholder="Email" onChange={this.onChange} />
                            <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
                            </div>
                            <div className="form-group">
                            <label>First Name</label>
                            <input type="text" id="firstName" className="form-control" placeholder="First Name" onChange={this.onChange} />
                            <span style={{color: "red"}}>{this.state.errors["firstName"]}</span>
                            </div>
                            <div className="form-group">
                            <label>Date of birth</label>
                            <div className="row" style={{marginLeft:'2px'}}><DatePicker id="dateOfBirth" className="form-control bggray" maxDate={this.state.myFutureDate} placeholderText="mm/dd/yyyy" selected={this.state.dateOfBirth}  onChange={date => this.datechange(date)} /> </div>
                            <br />
                            <span style={{color: "red"}}>{this.state.errors["dateOfBirth"]}</span>
                            </div>
                            { (this.state.otp == "") ?
                            <div className="form-group">
                                    <button type="button" onClick={this.sendOtp} className="btn btn-primary btn-full">Email One Time Password</button>
                                </div>
                            :""}
                            
                            { (this.state.otp != "") ?
                            <div>
                                <div className="form-group">
                                <label>One Time Password</label>
                                <input type="text" id="OneTimePassword" className="form-control" placeholder="Enter One Time Password" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["OneTimePassword"]}</span>
                                </div>
                                <div className="form-group">
                                <label>New Password</label>
                                <input type="password" id="UpdatedPassword" className="form-control" placeholder="Enter Password" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["UpdatedPassword"]}</span>
                                </div>
                                <div className="form-group">
                                <label>Confirm Password</label>
                                <input type="password" id="ConfirmPassword" className="form-control" placeholder="Enter Confirm Password" onChange={this.onChange} />
                                <span style={{color: "red"}}>{this.state.errors["ConfirmPassword"]}</span>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-full">Submit</button>
                                </div>
                                </div>
                                :""}
                                
                                

                                <div className="forgotpass-link">
                                    <a href="../">Login?</a>
                                    {/* <a href>Not a Member yet?</a> */}
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
                
            </MainLayout>
        );
    }
}



ForgotPassword.propTypes = {
    errors: PropTypes.object.isRequired,
    error: PropTypes.object.isRequired,
    updatePassword:PropTypes.func.isRequired,
    getOtp:PropTypes.func.isRequired,
    
};

const mapStateToProps = (state) => ({
    page_name : state.pay,
    errors: state.errors,
  });


export default connect(mapStateToProps, {updatePassword,getOtp})(ForgotPassword);